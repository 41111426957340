import type { StrapiArticle } from '~/apollo/types/types';

export const useArticlePublishedDate = (
  article: MaybeRef<StrapiArticle>,
  format?: 'short' | 'long',
) => {
  const { formatFn } = useDate(format);

  const articleRef = isRef(article) ? article : ref(article);

  const publishedAt = computed(() => {
    if (articleRef.value == null) {
      return null;
    }

    if (articleRef.value.publishedAt == null) {
      return null;
    }
    return formatFn(articleRef.value.publishedAt);
  });

  return {
    articleRef,
    publishedAt,
  };
};

export const useArticleLink = (article: MaybeRef<StrapiArticle>) => {
  const articleRef = isRef(article) ? article : ref(article);

  const linkToArticle = computed<string>(
    () =>
      `/${articleRef.value.locale}/${articleRef.value.type.toLowerCase()}/${
        articleRef.value.slug
      }`,
  );

  return {
    articleRef,
    linkToArticle,
  };
};
