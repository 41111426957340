export default (format?: 'long' | 'short') => {
  const { locale } = useI18n();
  const currencyStore = useCurrencyStore();

  const dateTimeFormat = computed(() => {
    let options: Intl.DateTimeFormatOptions = {};

    if (format === 'long') {
      options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      };
    }

    if (locale.value === 'en') {
      const currency = currencyStore.currency;
      if (currency === 'USD') {
        return new Intl.DateTimeFormat('en-us', options);
      }

      return new Intl.DateTimeFormat('en-gb', options);
    }

    return new Intl.DateTimeFormat(locale.value, options);
  });

  const formatFn = (date: string | Date | number) => {
    let d = null;
    if (typeof date === 'string' || typeof date === 'number') {
      d = new Date(date);
    } else {
      d = date;
    }

    return dateTimeFormat.value.format(d);
  };

  return {
    formatFn,
    dateTimeFormat,
  };
};
